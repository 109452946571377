// External Dependencies
import { json, redirect } from "@remix-run/node";
import { Form, Link, useActionData, useSearchParams } from "@remix-run/react";
import { metaV1 } from "@remix-run/v1-meta";
import * as React from "react";

// Internal Dependencies
import RootErrorContent from "~/components/ui/display/RootErrorContent";
import ApollosGradient from "~/components/ui/icons/ApollosGradient";
import Button from "~/components/ui/inputs/Button";
import { requestNewOTP } from "~/models/sendgrid.server";
import {
  getUserByEmail,
  updateUserPassword,
  verifyEmail,
  verifyLogin,
} from "~/models/user.server";
import { createUserSession } from "~/session.server";
import { generateOTP, safeRedirect, validateEmail } from "~/utils";
import { getRedirectUrlForUser } from "~/utils/getRedirectUrlForUser";
import { withUserRedirect } from "~/utils/withUserRedirect";

export function ErrorBoundary() {
  return <RootErrorContent />;
}

export const loader = withUserRedirect();

export async function action({ request, params }) {
  const formData = await request.formData();
  const email = formData.get("email").toLowerCase();
  const password = formData.get("password");
  let redirectTo = safeRedirect(formData.get("redirectTo"), "/");
  const remember = formData.get("remember");

  if (!validateEmail(email)) {
    return json(
      { errors: { email: "Email is invalid", password: null } },
      { status: 400 }
    );
  }

  let user = await verifyLogin(email, password);

  const emailOTP = {
    "nathan@differential.com": "password1",
  };

  let searchParams = {
    email: email,
    otp: emailOTP[email] || "",
  };

  const env = process.env.NODE_ENV || "production";

  if (env === "development") {
    if (
      email === "nathan@differential.com" ||
      email === "test1@apollos.app" ||
      email === "test3@apollos.app" ||
      email === "apps@apollos.app"
    ) {
      user = await getUserByEmail(email);
      const redirectUrl = await getRedirectUrlForUser(params, user?.id);

      if (redirectUrl) redirectTo = redirectUrl;

      return createUserSession({
        request,
        userId: user?.id,
        remember: remember === "on" ? true : false,
        redirectTo,
        searchParams,
      });
    }
  }

  if (!user) {
    const emailExists = await verifyEmail(email);

    if (emailExists) {
      const { email } = emailExists;
      const otp = generateOTP();

      await updateUserPassword(emailExists, otp);
      await requestNewOTP(email, otp);

      return redirect(`/email?email=${encodeURIComponent(email)}`);
    } else {
      return json({ emailExists: false });
    }
  }

  user = await getUserByEmail(email);
  const redirectUrl = await getRedirectUrlForUser(params, user?.id);

  if (redirectUrl) redirectTo = redirectUrl;

  return createUserSession({
    request,
    userId: user.id,
    remember: remember === "on" ? true : false,
    redirectTo,
  });
}

export const meta = (args) =>
  metaV1(args, {
    title: "Login",
  });

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo") || "/";
  const actionData = useActionData();
  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const email = searchParams.get("email") || "";
  const otp = searchParams.get("otp") || "";
  const [showSignupSuggestion, setShowSignupSuggestion] = React.useState(false);

  React.useEffect(() => {
    if (actionData?.emailExists === false) {
      setShowSignupSuggestion(true);
    }

    if (actionData?.errors?.email) {
      emailRef.current?.focus();
    } else if (actionData?.errors?.password) {
      passwordRef.current?.focus();
    }
  }, [actionData]);

  return (
    <div className="relative flex h-full w-full flex-col items-center px-8 pt-24 pb-12 text-center font-sans">
      <div className="absolute inset-0 bg-gradient-to-br from-gradientFaded-topLeft to-gradientFaded-bottomRight"></div>

      <div className="my-auto flex w-full flex-col items-center gap-4 rounded-xl bg-white px-10 py-8 drop-shadow-md sm:w-[440px]">
        <ApollosGradient />
        <div className="w-full gap-8">
          <div className="flex w-full flex-col items-start gap-1 self-stretch">
            {email && otp ? (
              <p className="m-0 w-full text-2xl font-semibold leading-10 text-black">
                Almost there!
              </p>
            ) : (
              <p className="m-0 w-full text-2xl font-semibold leading-10 text-black">
                Log in to your account
              </p>
            )}
            {email && otp ? (
              <p className="m-0 w-full text-base font-normal leading-6 text-gray-600">
                Click the button below to login.
              </p>
            ) : null}
          </div>
        </div>
        <Form method="post" className="w-full" noValidate>
          {email && otp ? (
            <>
              <input
                id="email"
                name="email"
                type="hidden"
                defaultValue={email}
                placeholder="Enter your email"
              />
              <input
                id="password"
                name="password"
                type="hidden"
                readOnly
                defaultValue={otp}
              />
              <Button primary size="md" fullWidth styles="mt-2" type="submit">
                Continue
              </Button>
            </>
          ) : (
            <>
              <div className="gap-5">
                <div
                  className={`gap-1.5 text-left font-sans font-normal text-gray-500`}
                >
                  <div className="gap-1.5">
                    <input
                      ref={emailRef}
                      id="email"
                      required
                      autoFocus={true}
                      name="email"
                      type="email"
                      autoComplete="email"
                      defaultValue={email}
                      placeholder="Enter your email"
                      aria-invalid={
                        actionData?.errors?.email ? true : undefined
                      }
                      aria-describedby="email-error"
                      className="w-full appearance-none rounded-md border border-gray-300 px-3.5 py-2.5 text-gray-500 placeholder-gray-500 focus:z-10 focus:outline-primary-500 focus:ring-primary-500 sm:text-sm"
                    />

                    {showSignupSuggestion ? (
                      <p className="my-4 text-sm text-center text-error-700">
                        It looks like this email isn't linked to an account. Did
                        you mean to{" "}
                        <Link
                          to={`/signup?email=${encodeURIComponent(emailRef?.current?.value)}`}
                          className="text-primary-500 hover:text-primary-600"
                          prefetch="intent"
                        >
                          sign up{" "}
                        </Link>
                        instead?
                      </p>
                    ) : null}

                    {actionData?.errors?.email ? (
                      <div
                        className="pt-1 text-sm text-red-700"
                        id="email-error"
                      >
                        {actionData.errors.email}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="relative">
                <div className="mt-1">
                  <input
                    id="password"
                    ref={passwordRef}
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    className="hidden"
                    readOnly
                    defaultValue={otp}
                    aria-invalid={
                      actionData?.errors?.password ? true : undefined
                    }
                    aria-describedby="password-error"
                  />

                  {actionData?.errors?.password ? (
                    <div
                      className="pt-1 text-sm text-red-700"
                      id="password-error"
                    >
                      {actionData.errors.password}
                    </div>
                  ) : null}
                </div>
              </div>

              <input type="hidden" name="redirectTo" value={redirectTo} />
              <Button primary size="md" fullWidth styles="mt-2" type="submit">
                Continue with email
              </Button>
            </>
          )}
        </Form>
        <p className="mt-4 text-sm text-center text-gray-500">
          Don't have an account?{" "}
          <Link
            to="/signup"
            prefetch="intent"
            className="text-primary-500 hover:text-primary-600"
          >
            Sign up
          </Link>
        </p>
      </div>
    </div>
  );
}
